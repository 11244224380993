<template>
    <div id="app" :class="[$route.name=='search'? 'f5' : 'ff']">
        <!-- <div   v-if="$route.path =='/am/a' || $route.path =='/amD/am'"  :class="[$route.name=='search'? 'search-bg' : '']"  style="min-width: 1400px;min-height: 800px;">
            <div class="app-wrap">
                <router-view />
            </div>
        </div>

        v-else -->
        <div  :class="[$route.name=='search'? 'search-bg' : '']"  style="min-width: 1400px;min-height: 800px; background: #fff;">
            <Header v-if="$route.name !='am' && $route.name !='amD' && $route.name !='oa' && $route.name !='oaS' && $route.name !='preprint' && $route.name !='pS' && $route.name !='platform' && $route.name !='academicJournal' && $route.name !='ajS' && $route.name !='ajC' && $route.name !='ajA'"  />
            <div class="app-wrap">
                <router-view />
            </div>
            <Footer v-if="$route.name!='am' && $route.name!='amD'" />
        </div>
    </div>
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'

export default {
    components: { Header, Footer }
}
</script>

<style lang="scss">
#app {
    width: 100%;
    height: 100%;
    .app-wrap {
        min-height: 672px;
        background: #fff;
    }
    .search-bg {
        background: #f5f5f5;
    }
}
.f5 {
    background: #f5f5f5;
}
.ff {
    background: #fff;
}
/*.bugao {
    -webkit-filter: grayscale(100%);
-moz-filter: grayscale(100%);
-ms-filter: grayscale(100%);
-o-filter: grayscale(100%);
filter: grayscale(100%);
filter: gray; 
}*/
</style>
