import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import axios from "axios"
import $ from 'jquery'
import * as echarts from 'echarts'

import 'echarts-wordcloud'
// import echarts from 'echarts'
// import 'echarts-wordcloud'
import 'element-ui/lib/theme-chalk/index.css'
import './static/css/com.scss'
 



Vue.config.productionTip = false
Vue.use(Element)
Vue.prototype.$http = axios
Vue.prototype.$echarts = echarts


router.afterEach(()=>{
    window.scrollTo(0, 0)
    // next()

    setTimeout(()=>{

        (function() {
            //每次执行前，先移除上次插入的代码
            //百度统计
            var baidu_tj = document.getElementById('baidu_tj')
            if (baidu_tj !== undefined && baidu_tj !== null) {
                // if (isIE() || isIE11()) {
                //     // baidu_tj.removeNode(true);
                // } else {
                //     baidu_tj.remove();
                // }
                baidu_tj.remove();
            }

            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?c94610d0fb5fd5cfdd85c4d428dc7d16";
            hm.id = "baidu_tj";
            var s = document.getElementsByTagName("script")[0];
            var parentNode = s.parentNode;
            if (parentNode != null){
                parentNode.insertBefore(hm, s);
            }

            //社内统计
            var phpstat_async_js_id_10000019 = document.getElementById('phpstat_async_js_id_10000019')
            if (phpstat_async_js_id_10000019 !== undefined && phpstat_async_js_id_10000019 !== null) {
                // if (isIE() || isIE11()) {
                //     // phpstat_async_js_id_10000019.removeNode(true);
                // } else {
                //     phpstat_async_js_id_10000019.remove();
                // }
                phpstat_async_js_id_10000019.remove();
            }

            var hm1 = document.createElement("script");
            hm1.src = "//webstat.ssap.com.cn/phpstat/count/10000019/10000019.js";
            hm1.id = "phpstat_async_js_id_10000019";
            var s1 = document.getElementsByTagName("script")[0];
            var parentNode1 = s1.parentNode;
            if (parentNode1 != null) {
                parentNode1.insertBefore(hm1, s1);
            }

            //百度-社内-统计
            var baidu_ssap_tj = document.getElementById('baidu_ssap_tj')
            if (baidu_ssap_tj !== undefined && baidu_ssap_tj !== null) {
                // if (isIE() || isIE11()) {
                //     // baidu_ssap_tj.removeNode(true);
                // } else {
                //     baidu_ssap_tj.remove();
                // }
                baidu_ssap_tj.remove();
            }

            var hm_ssap_tj = document.createElement("script");
            hm_ssap_tj.src = "https://hm.baidu.com/hm.js?05fcece970bb627be0e9770defa14882";
            hm_ssap_tj.id = "baidu_ssap_tj";
            var s_ssap_tj = document.getElementsByTagName("script")[0];
            var parentNode_ssap_tj = s_ssap_tj.parentNode;
            if (parentNode_ssap_tj != null){
                parentNode_ssap_tj.insertBefore(hm_ssap_tj, s_ssap_tj);
            }

        })();
    },0);

})

// /**
//  * 判断是否是IE
//  */
// function isIE() {
//     return !!window.ActiveXobject || "ActiveXObject" in window;
// }
// /**
//  * 判断是否是IE11
//  */
// function isIE11(){
//     return (/Trident\/7\./).test(navigator.userAgent);
// }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
