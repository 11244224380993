<template>
  <div class="header-component-wrap">
    <!-- <div class="annual-wrap">
        <router-link to="/am/a">
            <img src="../assets/img/20.png" alt="" />
        </router-link>

    </div> -->
    <!-- <div class="annual-wrap" v-if="$route.name=='Home'">
        <router-link to="/am/a">
            <img src="../assets/img/20.jpg" alt="" />
        </router-link>
        </div> -->
<!--    <router-link v-if="$route.name=='Home'" to="/am/a" style="display: block;"><img  style="display: block; width: 100%;" src="../assets/img/annual-metting/banner1.png" alt="" /></router-link>-->
    <div class="header-component">


      <div class="top-header">
        <div class="btn-header nav-fr" v-if="!isLogin">
          <!-- <span class="logon">登录</span>
      <span class="login">注册</span> -->
          <div class="name person personshort" v-show="!userMsg.isLogined">
            <a @click="goLogin($event)" style="cursor: pointer;">登录</a> <span>|</span> <a
              @click="goLogon($event)">注册</a>
          </div>
          <div class="name person" v-show="userMsg.isLogined">
            <div class="login_btn" v-if="userMsg.UserName">
              <a href="https://vip.ssap.com.cn/vip/member/info?SiteID=12&SourceID=4" class="login-a"
                 :title="userMsg.UserName">{{ userMsg.UserName }}</a>|<a :href="userMsg.LoginURL">更换用户</a>|
              <a :href="userMsg.LogoutURL" @click="outUser(userMsg.LogoutURL,$event)">退出</a>
            </div>
            <div class="login_btn" v-else>
              <a class="login-a" :title="userMsg.BranchInfoName">{{ userMsg.BranchInfoName }}(机构)</a>|<a
                :href="userMsg.LoginURL" @click="changeUser(userMsg.LoginURL, $event)">更换用户</a>
            </div>
          </div>
        </div>
        <div class="welcome-header" v-else>
          <span>您好，</span>欢迎来到集刊全文数据库！
        </div>
        <!-- <div class="top-right-header">
        <span>手机版</span> |
        <span>帮助</span>
    </div> -->
      </div>
      <div class="main-header wid1200">
        <div class="logo-header-wrap fl">
          <router-link to="/">
            <img class="logo-header" src="../assets/img/logo.png" alt=""/>
          </router-link>

        </div>
        <div class="search-wrap  fl">
          <ul class="search-ul">
            <li :class="activeSource == item.value ? 'active-source' : '' "
                v-for="(item, ind) in sourceType" :key="ind" @click="switchSource(item)">{{ item.label }}
            </li>
            <!-- <li>图片</li>
        <li>图表</li> -->
          </ul>
          <div class="search-input">
            <div class="search-border-wrap">
              <div class="search-select-wrap">
                <el-select ref="selectInput" v-model="value" placeholder="请选择" @change="switchType"
                           style="width: 90px;">
                  <el-option v-for="item in options" :key="item.value" :label="item.label"
                             :value="item.value">
                  </el-option>
                </el-select>
              </div>
              <div class="search-input-wrap">
                <el-input placeholder="请输入搜索内容" v-model="searchKey" @keyup.enter.native="search">
                </el-input>
              </div>
              <el-button class="search-btn" type="warning" @click="search">
                <img src="../assets/img/searchicon.png" alt="" style="width: 29px;"/>

              </el-button>
            </div>

            <span class="high-search" @click.stop="highSearch($event)">高级检索</span>
          </div>
          <div class="hot-words">
            热词推荐：<span v-for="(i, ind) in hotData" :key="ind" @click="hotSearch(i)">{{ i }}</span>
          </div>
        </div>
        <div class="clear"></div>

      </div>
      <!-- <ul class="count-header-wrap wid1200">
      <li><span>{{countObj.cp}}</span>种集刊</li>
      <li><span>{{countObj.book}}</span>辑集刊</li>
      <li><span>{{countObj.corpus}}</span>种文集</li>
      <li><span>{{countObj.article}}</span>篇文章</li>
  </ul> -->
      <div class="bot-header-wrap">
        <ul class="bot-header wid1200">
          <li class="all-type">
            <router-link to="/"><img src="../assets/img/all.png" alt=""/>全部分类</router-link>
          </li>
          <li>
            <router-link to="/">首页</router-link>
          </li>
          <!-- <li> <router-link  :to="{path: '/sD/s', query: {id: 1757288}}">关于集刊</router-link> </li> -->
          <li class="">

            <el-dropdown style="width: 150px; height: 50px; text-align: center;">
                            <span class="el-dropdown-link" style="color: #fff; font-size: 21px;">
                                学科子库
                            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in libraryData" :key="item.id">
                  <router-link :to="{path: '/library/l', query: {id: item.id}}" style="color: #333;">
                    {{ item.name }}
                  </router-link>
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </li>
          <li>
            <router-link to="/oa/openaccess">开放获取</router-link>
          </li>
          <li>
            <router-link to="/preprint/p">预印本</router-link>
          </li>
          <li>
            <router-link to="/guide/g">集刊导览</router-link>
          </li>
          <li class="">

            <el-dropdown style="width: 112px; height: 50px; text-align: center;">
                            <span class="el-dropdown-link" style="color: #fff; font-size: 21px;">
                                集刊服务
                            </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item>
                  <a href="https://www.iedol.cn/#/login" target="_blank"
                     style="color: #333;">投约稿系统</a>
                </el-dropdown-item>
                <el-dropdown-item>
                  <!-- <router-link :to="{path: '/iedolNum/i'}" style="color: #333;">集刊编号注册</router-link> -->
                  <router-link :to="{path: '/sD/s', query: {id: '1001'}}" style="color: #333;">集刊编号注册
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{path: '/oaIntro/o', query: {id: 'jkgf_gyjkgf'}}"
                               style="color: #333;">集刊规范
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{path: '/am/a'}"
                               style="color: #333;">集刊年会
                  </router-link>
                </el-dropdown-item>
                <el-dropdown-item>
                  <router-link :to="{path: '/aj/a'}"
                               style="color: #333;">集刊目录
                  </router-link>
                </el-dropdown-item>

              </el-dropdown-menu>
            </el-dropdown>
          </li>
        </ul>
      </div>

    </div>
  </div>

</template>

<script>
export default {
  name: '',
  data() {
    return {
      isLogin: false,
      activeSource: '',
      sourceType: [
        {
          value: '',
          label: '全部'
        },
        {
          value: 'COLLECTED_PAPERS',
          label: '集刊'
        },
        {
          value: 'WENJI',
          label: '文集'
        },
        {
          value: 'ARTICLE',
          label: '文章'
        }
      ],
      options: [
        {
          value: 1,
          label: '全文'
        },
        {
          value: 2,
          label: '标题'
        },
        {
          value: 3,
          label: '关键词'
        },
        {
          value: 4,
          label: '作者'
        }
      ],
      libraryData: [],
      value: 1,
      searchKey: '',
      countObj: {},
      hotData: [],
      curValue: 0,
      curSearchKey: '',
      activeValue: '',
      userMsg: '',
      countNum: 1
    }
  },
  watch: {
    $route: 'init'
  },
  methods: {
    init() {
      this.curValue = this.$route.query.type
      this.curSearchKey = this.$route.query.searchKey
      this.activeSource = ''
      this.searchKey = ''
      this.value = 1

      this.getHot()
      this.getLibrary()
      this.getCountData()
      if (this.$route.name == 'search') {
        this.value = parseInt(this.$route.query.type)
        this.searchKey = this.$route.query.searchKey
      }

      if (this.$route.query.searchType) {
        this.activeSource = this.$route.query.searchType
      } else {
        this.activeSource = ''
      }
    },
    highSearch(e) {
      // '/highSearch/h'
      this.$router.push({path: '/highSearch/h'})
    },
    switchSource(item) {
      this.activeSource = item.value
    },
    goLogin(e) {
      e.preventDefault()
      _trackData.push(['addaction', '顶部导航', '登录按钮'])
      this.encodeURI2()
      window.location.href = '/admin/login.za?ZAS_referer=' + this.encodeURI2()
    },
    encodeURI2() {
      var url = encodeURIComponent(window.location.origin + encodeURIComponent(window.location.pathname) + window.location.search + window.location.hash)
      //console.log(url)
      return url;
    },
    goLogon(e) {
      e.preventDefault()
      _trackData.push(['addaction', '顶部导航', '注册按钮'])
      setTimeout(function () {
        window.location.href = 'https://sso.ssap.com.cn/zas/register?AppID=14&ZAS_referer=' + this.encodeURI2()
      }, 100)
    },
    changeUser(login, e) {
      e.preventDefault()
      _trackData.push(['addaction', '顶部导航', '更换用户按钮'])
      setTimeout(function () {
        window.location.href = login
      }, 100)
    },
    outUser(logOut, e) {
      e.preventDefault()
      // _trackData.push(['addaction', '顶部导航', '退出按钮'])
      setTimeout(function () {
        window.location.href = logOut
      }, 100)
    },
    getUserInfo() {
      let _this = this
      _this
          .$http({
            method: 'get',
            url: '/admin/api/user/info',
            withCredentials: true,
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
            }
          })
          .then(res => {
            if (res.data.code == 0) {
              _this.userMsg = res.data.user
              // if (_this.userMsg.UserName) {
              //     _trackData.push(['userset', '用户名', _this.userMsg.UserName])
              // } else {
              //     _trackData.push(['userset', '用户名', _this.userMsg.BranchInfoName])
              // }
              if (!res.data.user.isLogined) {
                _this.countNum++
                let script = document.createElement('script')
                script.setAttribute('type', 'text/javascript')
                script.setAttribute('src', 'https://sso.ssap.com.cn/zas/sso.js')
                script.setAttribute('sso', 'init')
                script.setAttribute('parment', 'format=js&onlineCallBack=dologin&offlineCallBack=dologout&autoLogin=IP&AppID=14')
                let heads = document.getElementsByTagName('head')
                if (heads.length) heads[0].appendChild(script)
                else document.documentElement.appendChild(script)
                if (_this.countNum <= 3) {
                  setTimeout(_this.getUserInfo, 500)
                }
              }
            }
          })
    },
    getLibrary() {
      var _this = this
      this.$http({
        method: 'get',
        url: '/admin/api/library/list?parentId=1'
      }).then(res => {
        if (res.data.code == 0) {
          _this.libraryData = res.data.data.rows
        }
      })
    },
    hotSearch(i) {
      this.searchKey = i
      this.$router.push({
        path: '/search/s',
        query: {type: this.value, searchKey: this.searchKey, searchType: this.activeSource}
      })
    },
    getHot() {
      var _this = this
      this.$http({
        method: 'get',
        url: '/admin/api/index/hotWord'
      }).then(res => {
        _this.hotData = res.data
      })
    },
    getCountData() {
      var _this = this
      this.$http({
        method: 'get',
        url: '/admin/api/index/count'
      }).then(res => {
        _this.countObj = res.data
      })
    },
    resizeSelect() {
      this.$refs.selectInput.resetInputWidth()
    },
    switchType() {
    },
    search() {
      if (this.curValue == this.value && this.curSearchKey == this.searchKey) {
        this.$router.replace({
          path: '/search/s',
          query: {type: this.value, searchKey: this.searchKey + ' ', searchType: this.activeSource}
        })
      } else {
        this.curValue == this.value
        this.curSearchKey == this.searchKey
        this.$router.push({
          path: '/search/s',
          query: {type: this.value, searchKey: this.searchKey, searchType: this.activeSource}
        })
      }
    }
  },
  created() {
    this.init()
    this.getUserInfo()
  }
}
</script>

<style lang="scss" scoped>
.annual-wrap {
  width: 100%;
  /*height: 250px;*/
  margin-bottom: -10px;

  img {
    width: 100%;
    height: auto;
  }
}

.header-component {
  min-width: 1400px;
  // min-height: 362px;
  min-height: 312px;
  background: url(../assets/img/headerbg.jpg);
  background-size: 100% 100%;
  position: relative;

  .top-header {
    width: 1200px;
    margin: 0 auto 36px;
    line-height: 34px;
    display: flex;
    justify-content: flex-start;
    justify-content: space-between;

    .btn-header {
      color: #cd0600;

      .login {
        margin-left: 22px;
      }

      span {
        cursor: pointer;
      }
    }

    .nav-fr .name {
      display: inline-block;
      margin-left: 10px;
      color: #cd0600;
    }

    .nav-fr .name:before {
      margin-top: 15px;
      content: '';
      float: left;
      width: 26px;
      height: 26px;
    }

    .nav-fr .name.person.personshort {
      width: 130px;
    }

    .nav-fr .name.person {
      // width: 255px;
    }

    // .nav-fr .name.person:before {
    //     background: url('../assets/img/icon_user.png') no-repeat;
    //     background-size: cover;
    // }
    .nav-fr .name.book:before {
      background: url('../assets/img/icon_book.png') no-repeat;
      background-size: cover;
    }

    .nav-fr .name a {
      margin-right: 0;
      font-weight: 500;
      color: #cd0600;
    }

    .name .login_btn {
      display: flex;

      a.login-a {
        display: block;
        max-width: 220px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .name span {
      margin: 0 5px;
    }

    .welcome-header {
      color: #404040;

      span {
        color: #cd0600;
        cursor: pointer;
      }
    }

    .top-right-header {
      span {
        cursor: pointer;
      }
    }
  }

  .main-header {
    .logo-header-wrap {
      padding-top: 4px;

      img.logo-header {
        width: 361px;
        height: 104px;
      }
    }

    .search-wrap {
      margin-left: 69px;

      ul {
        display: flex;
        margin-bottom: 10px;

        li {
          height: 28px;
          padding: 0 16px;
          line-height: 28px;
          margin-right: 14px;
          color: #292929;
          cursor: pointer;
        }

        li:hover {
        }

        .active-header {
          color: #fff;
          background: #bd1a2d;
        }
      }

      .search-ul {
        // li {

        // }
        .active-source {
          color: #fff;
          background: #bd1a2d;
        }
      }

      .search-input {
        display: flex;

        .search-border-wrap {
          float: left;
          color: #fff;
        }

        .search-icon {
          font-size: 26px;
          line-height: 0.46rem;
          color: #fff;
          cursor: pointer;
        }

        .search-btn {
          padding: 0 18px;
          border-radius: 0%;
          float: right;
          color: #fff;
          font-weight: 700;
        }

        .high-search {
          margin-left: 15px;
          font-size: 17px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          line-height: 54px;
          cursor: pointer;
        }
      }

      .hot-words {
        margin-top: 12px;
        height: 15px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #404040;

        span {
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
  }

  .count-header-wrap {
    display: flex;
    justify-content: center;
    margin-top: 43px;

    li {
      background: #fffdf8;
      line-height: 36px;
      min-width: 124px;
      text-align: center;
      color: #404040;
      margin-right: 92px;

      span {
        color: #cd0600;
      }
    }

    li:last-child {
      margin-right: 0;
    }
  }

  .bot-header-wrap {
    line-height: 50px;
    width: 100%;
    background: rgba(189, 26, 45, 0.84);
    position: absolute;
    bottom: 0;
    left: 0;

    ul.bot-header {
      display: flex;

      .all-type {
        background: url(../assets/img/navicon.png);
        background-size: 100% 100%;
      }

      li {
        padding: 0 41px;
        display: flex;
        align-items: center;

        font-size: 21px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #fefefe;
        cursor: pointer;

        img {
          width: 18px;
          height: 18px;
          margin-right: 9px;
        }

        a {
          color: #fff;
        }
      }

      li:nth-child(3) {
        padding: 0 34px;
      }

      li:nth-child(4) {
        padding-left: 30px;
      }

      li:first-child {
        padding: 0 70px;
      }

      li:nth-child(2) {
        padding-left: 40px;
        padding-right: 30px;
      }

      li:last-child {
        padding-right: 0px;
      }
    }
  }
}
</style>
