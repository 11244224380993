<template>
    <div class="footer-page">

        <div class="recommend-link wid1200">
            <div class="product-link">
                <h5><span>产品链接</span></h5>
                <ul>
                    <li  v-for="(item, index) in productLink" :key="index"><a :href="item.domain" target="_blank">{{item.siteName}}</a></li>
                    <!-- <li><a href="#">一带一路数据库</a></li>
                    <li><a href="#">国别区域与全球治理数据平台</a></li>
                    <li><a href="#">减贫研究数据库</a></li>
                    <li><a href="#">京津冀协同发展数据库</a></li>
                    <li><a href="#">乡村研究数据库</a></li>
                    <li><a href="#">台湾大陆同乡会文献数据库</a></li> -->
                </ul>
            </div>
            <div class="friendly-link">
                <h5><span>友情链接</span></h5>
                <ul>
                    <li v-for="(item, index) in friendLink" :key="index"><a :href="item.domain" target="_blank">{{item.siteName}}</a></li>
                    <!-- <li><a href="#">贵州师范大学</a></li>
                    <li><a href="#">中国海洋大学</a></li>
                    <li><a href="#">中国佛学院</a></li>
                    <li><a href="#">首都师范大学文化研究院</a></li>
                    <li><a href="#">南开大学世界近代史研究中心</a></li>
                    <li><a href="#">贵州省社会科学院</a></li>
                    <li><a href="#">贵州师范大学</a></li>
                    <li><a href="#">中国海洋大学</a></li>
                    <li><a href="#">中国佛学院</a></li>
                    <li><a href="#">首都师范大学文化研究院</a></li>
                    <li><a href="#">南开大学世界近代史研究中心</a></li> -->
                </ul>
            </div>


            <!-- <div class="recommend-wrap">
                <h5 class="fl">产品推荐 </h5>
                <p class="fl"><span v-for="(item, index) in productLink" :key="index"><a :href="item.domain" target="_blank">{{item.siteName}}</a></span></p>
                <div class="clear"></div>
            </div>
            <div class="link-wrap">
                <h5 class="fl">友情链接</h5>
                <p class="fl">
                    <span v-for="(item, index) in friendLink" :key="index"><a :href="item.domain" target="_blank">{{item.siteName}}</a></span>

                </p>
                <div class="clear"></div>
            </div> -->
        </div>
        <div class="top-footer-wrap">
            <div class="top-footer wid1200">
                <div class="left-top-footer com-footer">
                    <p>北京市西城区北三环中路甲29号院3号楼"华龙大厦"A/B座13层、15层 | 邮编：100029</p>
                    <p>编辑服务联系电话：010-59366533 | 销售服务联系电话：400-0086-695 | 客服qq：2475522410</p>
                    <p>邮箱：database@ssap.cn | 您当前的IP是：{{ipNum}}</p>
                </div>
                <div class="right-top-footer com-footer">
                    <div>
                        <img src="../assets/img/code1.png" alt="">
                        <p>手机版</p>
                    </div>
                    <div>
                        <img src="../assets/img/code2.jpg" alt="">
                        <p>公众号</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="bot-footer-wrap">
            版权所有 社会科学文献出版社　<a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" style="color: #010101;">京ICP备06036494号-27</a> 　<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202010288" style="color: #010101;margin-left: 8px;"><img src="../assets/img/net.jpg" style="vertical-align: middle; margin-right: 1px;"/>京公网安备 11010202010288号</a>　新出网证（京）字094号
        </div>
        <!-- <a href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=110102003507" target="_blank" style="color: #010101; margin-left: 8px;"> 京公网安备110102003507</a> -->
        <!-- <div class="bot-footer-wrap">
            版权所有 社会科学文献出版社　<a target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010202010288" style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img src="" style="float:left;"/><p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#939393;">京公网安备 11010202010288号</p></a>
        </div> -->

    </div>
</template>

<script>
export default {
    name: '',
    data() {
        return {
            ipNum:'',
            friendLink : [],
            productLink: []
        }
    },
    mounted(){
        this.ipNum = returnCitySN["cip"];
    },
    methods: {
        getUrl () {
            var _this = this
            this.$http({
                method: 'get',
                url: '/admin/api/index/link'
            }).then(res => {
                    // _this.resourceData = res.data.data
                    // e.childLibraryData = res.data.data.rows
                    // _this.libraryData.unshift(e)
                    // _this.dynamicData = res.data.data.slice(0, 7)
                    _this.friendLink = res.data.friendLink
                    _this.productLink = res.data.productLink

            })
        }
    },
    created () {
        this.getUrl()
    }
}
</script>

<style lang="scss" scoped>
.footer-page {
    width: 100%;
    background: #fff;
    .recommend-wrap {
        margin-top: 30px;
    }
    .recommend-link {

        h5 {
            text-align: center;
            position: relative;
            z-index: 0;
            display: flex;
            justify-content: center;
            margin-bottom: 22px;
            span {
                display: block;
                width: 100px;
                text-align: center;
                color: #2B221B;
                font-size: 18px;
                line-height: 18px;
                z-index: 2;
                background: #fff;
                font-weight: bold;
            }
        }
        h5::after {
            content: '';
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 100%;
            height: 1px;
            background: #EAEFF3;
            z-index: -1;
        }
        div {
            margin-top: 30px;
            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    margin: 0 32px 16px 0;
                    a {
                        display: block;
                        font-size: 14px;
                        color: #777777;
                        line-height: 14px;
                    }
                }
            }
        }


    }
    .top-footer-wrap {
        margin-top: 31px;
        background: #bd1a2d;
        /*height: 190px;*/
        box-sizing: border-box;
        padding: 19px 0;
        .top-footer {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .left-top-footer {
                width: 892px;
                box-sizing: border-box;
                padding: 48px 32px 44px 41px;

                p {
                    text-align: left;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #dfe0e6;
                    line-height: 30px;
                }
            }
            .right-top-footer {
                width: 298px;
                height: 152px;
                display: flex;
                box-sizing: border-box;
                justify-content: space-between;
                padding: 13px 20px 0 25px;
                div {
                    img {
                        width: 106px;
                        height: 105px;
                    }
                    p {
                        line-height: 26px;
                        font-size: 14px;
                        font-family: Microsoft YaHei;
                        font-weight: 400;
                        color: #dfe0e6;
                        text-align: center;
                    }
                }
            }
            .com-footer {
                background: #a91728;
            }
        }
    }
    .bot-footer-wrap {
        background: #dabb85;
        text-align: center;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #010101;
        line-height: 50px;
    }
}
</style>
