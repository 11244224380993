import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
    // {
    //     path: '/',
    //     name: 'Home',
    //     component: Home
    // },
    {
        path: '/home',
        redirect: '/'
    }, {
        path: '/',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
    }, {
        path: '/:id',
        name: 'comPage',
        component: () => import('@/views/ComPage.vue'),
    }, {
        path: '/search/s', //检索结果页
        name: 'search',
        component: () => import('@/views/Search.vue'),
    }, {
        path: '/secLibrary/s',//二级子库页面
        name: 'secLibrary',
        component: () => import('@/views/Search.vue'),
    }, {
        path: '/high/h',//高级检索
        name: 'high',
        component: () => import('@/views/Search.vue'),
    }, {
        path: '/library/l',//子库页面
        name: 'library',
        component: () => import('@/views/Library.vue')
    }, {
        path: '/chart/c',
        name: 'chart',
        component: () => import('@/views/Chart.vue'),
    }, {
        path: '/chartDetail/c',
        name: 'chartDetail',
        component: () => import('@/views/ChartDetail.vue'),
    }, {
        path: '/aD/a',//文章详情页
        name: 'aD',
        component: () => import('@/views/ArticleDetail.vue'),
    }, {
        path: '/readOnline/r',//在线阅读 全文阅读
        name: 'readOnline',
        component: () => import('@/views/ReadOnline.vue')
    }, {
        path: '/collectionDetail/c',
        name: 'collectionDetail',
        component: () => import('@/views/CollectionDetail.vue')
    }, {
        path: '/subject/subject',
        name: 'subject',
        component: () => import('@/views/Subject.vue')
    }, {
        path: '/ss/ss',
        name: 'ss',
        component: () => import('@/views/test.vue')
    }, {
        path: '/oa/openaccess',
        name: 'oa',
        component: () => import('@/views/Oa.vue')
    }, {
        path: '/aj/a',
        name: 'academicJournal',
        component: () => import('@/views/AcademicJournal.vue')
    }, {
        path: '/ajS/a',
        name: 'ajS',
        component: () => import('@/views/AcademicJournalSearch.vue')
    }, {
        path: '/ajC/a',
        name: 'ajC',
        component: () => import('@/views/AcademicJournalCollectedPapers.vue')
    }, {
        path: '/ajA/a',
        name: 'ajA',
        component: () => import('@/views/AcademicJournalAppointment.vue')
    }, {
        path: '/oaS/o',
        name: 'oaS',
        component: () => import('@/views/SearchOaPreprint.vue')
    }, {
        path: '/oa/openaccess1',
        name: 'oa1',
        component: () => import('@/views/Oa1.vue')
    }, {
        path: '/preprint/p',
        name: 'preprint',
        component: () => import('@/views/Preprint.vue')
    }, {
        path: '/platform/p',
        name: 'platform',
        component: () => import('@/views/Platform.vue')
    }, {
        path: '/pS/p',
        name: 'pS',
        component: () => import('@/views/SearchOaPreprint.vue')
    }, {
        path: '/preprint/p1',
        name: 'preprint1',
        component: () => import('@/views/Preprint1.vue')
    }, {
        path: '/pD/p',
        name: 'pD',
        component: () => import('@/views/NewsDetail.vue')
    }, {
        path: '/eDA/e',
        name: 'eDA',
        component: () => import('@/views/NewsDetail.vue')           
    }, {
        path: '/iDA/i',
        name: 'iDA',
        component: () => import('@/views/NewsDetail.vue')           
    }, {
        path: '/pDB/p',
        name: 'pDB',
        component: () => import('@/views/CollectionDetail.vue')
    }, {
        path: '/eDB/e',
        name: 'eDB',
        component: () => import('@/views/CollectionDetail.vue')
    }, {
        path: '/iDB/i',
        name: 'iDB',
        component: () => import('@/views/CollectionDetail.vue')
    }, {
        path: '/expert/e',
        name: 'expert',
        component: () => import('@/views/Expert.vue')
    }, {
        path: '/ins/i',
        name: 'ins',
        component: () => import('@/views/Institution.vue')
    }, {
        path: '/c/c', //集刊主页collected
        name: 'c',
        component: () => import('@/views/CollectedPapers.vue')
    }, {
        path: '/subjectDetail/s', //专题详情
        name: 'subjectDetail',
        component: () => import('@/views/SubjectDetail.vue')
    }, {
        path: '/news/n',
        name: 'news',
        component: () => import('@/views/News.vue')
    }, {
        path: '/nD/n',
        name: 'nD',
        component: () => import('@/views/NewsDetail.vue')
    }, {
        path: '/video/v',
        name: 'VideoList',
        component: () => import('@/views/VideoList.vue')
    }, {
        path: '/vD/v',
        name: 'vD',
        component: () => import('@/views/videoDetail.vue')
    }, {
        path: '/guide/g',
        name: 'guide',
        component: () => import('@/views/GuideCollect.vue')
    }, {
        path: '/oaIntro/o',
        name: 'oaIntro',
        component: () => import('@/views/ComIntro.vue')
    }, {
        path: '/preprintIntro/p',
        name: 'preprintIntro',
        component: () => import('@/views/ComIntro.vue')
    }, {
        path: '/highSearch/h',
        name: 'highSearch',
        component: () => import('@/views/HighSearch.vue')
    }, {
        path: '/iedolNum/i',
        name: 'iedolNum',
        component: () => import('@/views/IedolNum.vue')
    }, {
        path: '/sD/s',
        name: 'sD',
        component: () => import('@/views/ServiceDetail.vue')
    }, {
        path: '/cu/c',
        name: 'cu',
        component: () => import('@/views/cur.vue')
    }, {
        path: '/am/a',
        name: 'am',
        component: () => import('@/views/AnnualMeeting.vue')
    }, {
        path: '/amD/am',
        name: 'amD',
        component: () => import('@/views/AnnualMeetingDetail.vue')
    }


    // {
    //     path: '/about',
    //     name: 'About',
    //     // route level code-splitting
    //     // this generates a separate chunk (about.[hash].js) for this route
    //     // which is lazy-loaded when the route is visited.
    //     component: () => import(/* webpackChunkName: "about" */ '@/views/About.vue')
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router
